import React, { useEffect } from "react";
import Router from "next/router";
import getConfig from "next/config";
import NProgress from "nprogress";
import classnames from "classnames";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import App, {
  AppProps,
  AppInitialProps,
  AppContext as NextAppContext,
} from "next/app";
import Head from "next/head";
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { endpoints, headerPromotion, isLocalEnv } from "config";
import { AppContext } from "context/app";
import { ModalContext } from "context/modal";
import { UserContext } from "context/user";
import { CartContext } from "context/cart";
import { AnalyticsSessionParamsContext } from "context/analyticsSessionParams";
import { useAppProvider } from "context/hooks/useAppProvider";
import { useUserProvider } from "context/hooks/useUserProvider";
import { useCartProvider } from "context/hooks/useCartProvider";
import { useModalProvider } from "context/hooks/useModalProvider";
import { useAnalyticsSessionParamsProvider } from "context/hooks/useAnalyticsSessionParamsProvider";
import { Ccm } from "@pepdirect/ui/ccm";
import { shouldForwardProp } from "@pepdirect/v3/helpers/transientProps";

import "styles/index.scss";

/* Customizing swiper */
import "swiper/swiper.scss";
import "swiper/components/a11y/a11y.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/zoom/zoom.scss";
import "styles/swiper.scss";

/* Customizing modal */
import "styles/modal.scss";

import { PantryShopTheme } from "@pepdirect/ui/theme/PantryShopTheme";
import { useFontTracking } from "@pepdirect/helpers/useFontTracking";
import { FontTrackingiFrame } from "@pepdirect/ui/fontTracking";
import { NavHeader } from "components/Header";
import { NavFooter } from "components/Footer";
import { RootModal } from "components/Modals/Modal";

/* Importing and customizing nprogress */
import "styles/nprogress.scss";
import {
  META_TITLE,
  META_DESCRIPTION,
  META_DEFAULT_OG_IMAGE,
  CANONICAL_URL_BASE,
  PRIMARY_COLOR,
} from "constants/meta";

import "styles/stylingOverrides.scss";
import { useZipCodeProvider } from "context/hooks/useZipCodeProvider";
import { ZipCodeContext } from "context/zipCode";
import { useLogPageProvider } from "hooks/useLogPageView";
import { LogContext } from "context/log";
import { addGitShaToWindow } from "@pepdirect/helpers/gitSha";
import { generateFontFaces } from "@pepdirect/helpers/fonts";
import client from "services/graphql/client";
import {
  CurrentUserDocument,
  CurrentUserQuery,
  FeatureFlagsDocument,
  FeatureFlagsQuery,
  FeatureFlagsQueryVariables,
  TenantDocument,
  TenantQuery,
  TenantQueryVariables,
} from "services/graphql/generated";
import createSsrClient from "services/graphql/ssr-client";
import { isServerSide } from "helpers/routing";
addGitShaToWindow();

// Init progress bar
NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// Scroll to top after router.push();
Router.events.on("routeChangeComplete", () => window?.scrollTo(0, 0));

interface PantryAppInitialProps extends AppInitialProps {
  featureFlags: FeatureFlagsQuery;
  tenant: TenantQuery;
}

interface PantryAppProps extends AppProps {
  featureFlags: FeatureFlagsQuery;
  tenant: TenantQuery;
}

PantryShop.getInitialProps = async (
  appContext: NextAppContext
): Promise<PantryAppInitialProps> => {
  let gqlClient: ApolloClient<NormalizedCacheObject>;
  let userId: CurrentUserQuery["currentUser"] | null = null;
  if (isServerSide(appContext)) {
    // by the if check above, we guarantee that appContext will have request in it.
    gqlClient = createSsrClient(appContext?.ctx?.req);
  } else {
    gqlClient = client;
  }

  try {
    const userData = await gqlClient.query<CurrentUserQuery>({
      query: CurrentUserDocument,
    });
    userId = userData?.data?.currentUser?.id || null;
  } catch (e) {
    // logged in errorLink
  }

  // When we add cookies to the request for tenant and featureFlags queries,
  // if the cookie is not valid, then they return 401 unauthenticated error.
  // To prevent this, we detach cookies from the request before making request to them
  // if the user cookie is not valid. If cookie is not set, then this would not be a problem.
  if (!userId && isServerSide(appContext)) {
    gqlClient = createSsrClient(appContext?.ctx?.req, true);
  }

  /* eslint-disable no-useless-catch */
  try {
    const [appProps, featureFlagsData, tenantData] = await Promise.all([
      // calls page's `getInitialProps` and fills `appProps.pageProps`
      App.getInitialProps(appContext),
      gqlClient.query<FeatureFlagsQuery, FeatureFlagsQueryVariables>({
        query: FeatureFlagsDocument,
      }),
      gqlClient.query<TenantQuery, TenantQueryVariables>({
        query: TenantDocument,
      }),
    ]);

    return {
      ...appProps,
      featureFlags: featureFlagsData.data,
      tenant: tenantData.data,
    };
  } catch (e) {
    // make sure to re-throw after catch logs in errorLink so we show 500 error
    throw e;
  }
};

// Revisit this later if layout needs to change https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export default function PantryShop({
  Component,
  pageProps,
  featureFlags,
  tenant,
}: PantryAppProps): JSX.Element {
  const fonts = tenant.tenant?.brandConfig.trackingFonts || [];
  const title = tenant.tenant?.brandConfig.title || "PantryShop";
  const trackFontResources = useFontTracking(fonts, title);

  useEffect(() => {
    // exposes pod hostname to browser window
    if (window) {
      const { publicRuntimeConfig } = getConfig();
      window._pepdirect = {
        ...window._pepdirect,
        hostname: publicRuntimeConfig.hostname,
      };
    }
  }, []);

  const { appContextValue } = useAppProvider(
    featureFlags.featureFlags,
    tenant.tenant
  );
  const { modalContextValue } = useModalProvider();
  const { userContextValue } = useUserProvider(client);
  const { zipCodeContextValue } = useZipCodeProvider();
  const { analyticsParamsValue } = useAnalyticsSessionParamsProvider();
  const { useLogPageView } = useLogPageProvider(userContextValue.currentUserId);
  const { cartContextValue } = useCartProvider(
    client,
    userContextValue.currentUserId
  );

  const pageWrapperClasses = classnames("page-wrapper", {
    "with-promo": headerPromotion?.shouldShow,
  });

  return (
    <>
      <Head>
        {/* Meta tags not intended to change */}
        <meta name="theme-color" content={PRIMARY_COLOR} />
        <meta name="og:site_name" content={META_TITLE} />
        <meta name="og:price:currency" content="USD" />
        {/* Meta tags */}
        <meta name="og:title" content={META_TITLE} key="og:title" />
        <meta name="description" content={META_DESCRIPTION} key="description" />
        <meta
          name="og:description"
          content={META_DESCRIPTION}
          key="og:description"
        />
        <meta name="og:image" content={META_DEFAULT_OG_IMAGE} key="og:image" />
        <meta name="og:type" content="website" key="og:type" />
        <meta name="og:url" content={CANONICAL_URL_BASE} key="og:url" />
      </Head>
      <Ccm cdn={endpoints.cdn} />
      <style
        dangerouslySetInnerHTML={{
          __html: generateFontFaces(
            tenant?.tenant?.brandConfig?.customizations.fonts || []
          ),
        }}
      />
      {trackFontResources.map((resource) => (
        <FontTrackingiFrame
          resource={resource}
          key={resource}
          isLocalEnv={isLocalEnv}
        />
      ))}
      <ApolloProvider client={client}>
        <AppContext.Provider value={appContextValue}>
          <ThemeProvider theme={PantryShopTheme}>
            <ModalContext.Provider value={modalContextValue}>
              <UserContext.Provider value={userContextValue}>
                <CartContext.Provider value={cartContextValue}>
                  <LogContext.Provider value={{ useLogPageView }}>
                    <ZipCodeContext.Provider value={zipCodeContextValue}>
                      <AnalyticsSessionParamsContext.Provider
                        value={analyticsParamsValue}
                      >
                        <StyleSheetManager
                          shouldForwardProp={shouldForwardProp}
                        >
                          <div className="page-layout">
                            <NavHeader />
                            <main className={pageWrapperClasses}>
                              <Component {...pageProps} />
                            </main>
                            <div>
                              <NavFooter />
                            </div>
                          </div>
                          <RootModal />
                        </StyleSheetManager>
                      </AnalyticsSessionParamsContext.Provider>
                    </ZipCodeContext.Provider>
                  </LogContext.Provider>
                </CartContext.Provider>
              </UserContext.Provider>
            </ModalContext.Provider>
          </ThemeProvider>
        </AppContext.Provider>
      </ApolloProvider>
    </>
  );
}
