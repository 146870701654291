import React, { useState, useContext } from "react";

import { ModalContext } from "context/modal";
import { UserContext } from "context/user";
import { CartContext } from "context/cart";
import { endpoints, headerPromotion } from "config";

import { useIsMobile } from "@pepdirect/helpers/useIsMobile";
import { useRouterWithRedirect } from "hooks/useRouterWithRedirect";
import { NavHeader as PepNavHeader } from "@pepdirect/ui/NavHeader";
import { HeaderLocationProps } from "@pepdirect/ui/header/navheader/types";
import { logSignUp, logSignIn } from "@pepdirect/helpers/analyticsLogger";
import { LOGIN_LOCATION_ENUM } from "@pepdirect/shared/types";
import { ZipCodeContext } from "context/zipCode";
import { AppContext } from "context/app";
import { useGenericOnClickRouter } from "hooks/useGenericOnClickRouter";
import { isFeatureFlagEnabled } from "helpers/featureFlags";

export function NavHeader(): JSX.Element {
  const { open } = useContext(ModalContext);
  const { featureFlags } = useContext(AppContext);
  const { currentUserId, logout } = useContext(UserContext);
  const { getSize } = useContext(CartContext);
  const { zipCode } = useContext(ZipCodeContext);
  const size = getSize();
  const { pushPreserveRedirect } = useRouterWithRedirect();
  const { route } = useGenericOnClickRouter();

  const isMobile = useIsMobile();

  const [headerKey, setHeaderKey] = useState("h");

  const searchData = {
    showSearchBar: false,
    placeholderText: ``,
    onSearchHandler: () => {},
  };

  const goToSignIn = () => {
    if (isMobile) {
      // HACK to reset header, should be removed later
      setHeaderKey(`${headerKey}-1`);
      pushPreserveRedirect("/sign-in");
      logSignIn(LOGIN_LOCATION_ENUM.header);
    } else {
      open("LOGIN_MODAL");
    }
  };

  const goToSignUp = () => {
    if (isMobile) {
      // HACK to reset header, should be removed later
      setHeaderKey(`${headerKey}-1`);
    }
    logSignUp(LOGIN_LOCATION_ENUM.header);
    pushPreserveRedirect("/sign-up");
  };

  const logoutAndResetHeader = () => {
    if (isMobile) {
      // HACK to reset header, should be removed later
      setHeaderKey(`${headerKey}-1`);
    }
    logout();
  };

  const userData = {
    isLoggedIn: !!currentUserId,
    accountUrl: `${endpoints.portalFrontend}/v3/dashboard/account/`,
    onLogInClicked: goToSignIn,
    onLogOutClicked: logoutAndResetHeader,
    onSignUpClicked: goToSignUp,
  };

  const location: HeaderLocationProps = {
    showLocation: isFeatureFlagEnabled(featureFlags, "geo_location") || false,
    onClickHandler: () => {
      open("ZIPCODE_MODAL");
    },
    zipCode,
  };

  const headerData = {
    promotion: headerPromotion,
    search: searchData,
    logo: {
      logoImgLink: `${endpoints.cdn}/static-images/pantry-shop-icons/light-large-pantry-shop-logo.svg`,
      onClickHandler: (e: React.MouseEvent<HTMLAnchorElement>) => {
        route(e, "/");
      },
    },
    navBar: {
      onClickHandler: (e: React.MouseEvent<HTMLAnchorElement>) => route(e),
      navItems: [
        {
          navHeaderDisplay: `Pantry Kits`,
          links: [
            {
              display: `Shop All Bundles`,
              url: `/kit/all-bundles`,
            },
          ],
        },
        {
          navHeaderDisplay: `Beverages`,
          navHeaderLink: `/kit/beverages`,
        },
        {
          navHeaderDisplay: `Our Mission`,
          navHeaderLink: `/our-mission`,
        },
        {
          navHeaderDisplay: `Contact`,
          navHeaderLink: `https://contact.pepsico.com/pantryshop`,
          isOpenNewTab: true,
        },
      ],
      user: userData,
      searchBar: searchData,
      location,
    },
    shoppingCart: {
      numOfItems: size,
      onClickHandler: (e: React.MouseEvent<HTMLAnchorElement>) =>
        route(e, "/cart"),
    },
    user: userData,
    location,
  };

  return <PepNavHeader key={headerKey} {...headerData} />;
}
