import { useRouter } from "next/router";
import URI from "urijs";
import { endpoints } from "config";

type Route = (
  e: React.MouseEvent<HTMLAnchorElement>,
  specificUrl?: string
) => void;

export function useGenericOnClickRouter(): {
  route: Route;
} {
  const router = useRouter();

  const route: Route = (
    e?: React.MouseEvent<HTMLAnchorElement>,
    specificUrl?: string
  ) => {
    if (specificUrl) {
      e?.preventDefault && e?.preventDefault();
      router.push(specificUrl);
      return;
    }

    const href = e?.currentTarget.href;
    const isInternal = href?.includes(endpoints.web);
    if (isInternal) {
      e?.preventDefault && e?.preventDefault();
      const uri = URI(href);
      router.push(uri.pathname());
    }
  };

  return { route };
}
