import React, { useContext, useEffect } from "react";
import Modal from "react-modal";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { ModalContext } from "context/modal";

/* Modals */
import { CheckoutModal } from "./CheckoutModal";
import { CheckoutSubscriptionModal } from "./CheckoutSubscriptionModal";
import { ForgotPasswordModal } from "./ForgotPasswordModal";
import { LoginModal } from "./LoginModal";
import { ZipCodeModal } from "./ZipCodeModal";
import { UserContext } from "context/user";
import st from "./modal.module.scss";

export function RootModal(): JSX.Element {
  const { currentUserId } = useContext(UserContext);
  const { isOpen, close, type } = useContext(ModalContext);
  useEffect(() => {
    if (typeof window !== "undefined") {
      Modal.setAppElement("#__next");
    }
    // Cleanup just in case
    return clearAllBodyScrollLocks;
  }, []);

  return (
    <Modal
      onAfterClose={clearAllBodyScrollLocks}
      // Still need to check ref because the ref returns null while modal is closing.
      overlayRef={(ref) =>
        ref && disableBodyScroll(ref, { reserveScrollBarGap: true })
      }
      isOpen={isOpen}
      onRequestClose={() => close(currentUserId)}
      className={st.modal}
      overlayClassName={st.overlay}
      closeTimeoutMS={250}
    >
      <button
        className={st.close}
        onClick={() => close(currentUserId)}
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
      {/* TODO: lazy-load? */}
      {type === "CHECKOUT_MODAL" && <CheckoutModal />}
      {type === "CHECKOUT_SUBSCRIPTION_MODAL" && <CheckoutSubscriptionModal />}
      {type === "FORGOT_PASSWORD_MODAL" && <ForgotPasswordModal />}
      {type === "LOGIN_MODAL" && <LoginModal />}
      {type === "ZIPCODE_MODAL" && <ZipCodeModal />}
    </Modal>
  );
}
